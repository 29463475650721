<template>
  <a-layout class="custom-layout">
    <a-layout-sider class="custom-sider">
      <system-menu></system-menu>
    </a-layout-sider>
    <a-layout-content class="custom-content auto" style="position:relative;">
      <div class="drawer-bar auto">
        <div class="fl">
          <span class="font16"><b>数据导入导出</b></span>
          <a href="javascript:;" @click="goLog" class="go-log-btn">查看导入导出记录</a>
        </div>
      </div>
      <div class="department-content auto">
        <div class="batch-content auto">
          <a-spin :spinning="loading">
            <div class="batch-wrap auto">
              <div class="batch-title font16 auto">
                <b>数据恢复</b>
              </div>
              <div class="batch-top auto">
                导入说明：  本功能仅支持系统导出的文件
              </div>
              <div class="batch-upload auto">
                <a-upload-dragger
                  name="file1"
                  :showUploadList="false"
                  :action="config.api + 'backup/import/txt'"
                  @change="handleChange"
                >
                  <p class="ant-upload-drag-icon excel-icon">
                    <a-icon type="file-text" />
                  </p>
                  <p class="ant-upload-text excel-text">
                    将文件拖拽至此区域
                  </p>
                  <p class="ant-upload-hint excel-btn">
                    <a-button size="small">上传并恢复</a-button>
                  </p>
                </a-upload-dragger>
              </div>
            </div>
          </a-spin>
        </div>
        <div class="batch-content batch-content2 auto">
          <div class="batch-wrap auto">
            <div class="batch-title font16 auto">
              <b>数据备份</b>
            </div>
            <div class="batch-top auto">
              导出说明：本功能仅支持系统导出的文件，导出后请勿修改
            </div>
            <div class="batch-upload auto">
              <div class="batch-upload-box">
                <a-button size="small" @click="exportHandle">开始备份</a-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a-layout-content>
    <a-modal v-model="exportVisible" title="备份选项" @cancel="exportClose" @ok="exportSubmit" width="800px">
      <a-form-model
          id="no-margin-form"
          ref="exportForm"
          :model="exportForm"
          class="ant-advanced-search-form"
          :label-col="{span: 3}"
          :wrapper-col="{span: 21}"
        >
        <a-form-model-item label="是否混淆" prop="obfuscation">
          <a-radio-group :options="options" v-model="exportForm.obfuscation" />
        </a-form-model-item>
        <a-form-model-item label="姓名是否加密" prop="encryption">
          <a-radio-group :options="options" v-model="exportForm.encryption" />
        </a-form-model-item>
        <a-form-model-item label="导出类型" prop="exporttype">
          <a-radio-group :options="exporttypeOptions" v-model="exportForm.exporttype" />
        </a-form-model-item>
        <a-form-model-item label="导出类型" prop="exportfilter">
          <a-radio-group :options="exportfilterOptions" v-model="exportForm.exportfilter" />
        </a-form-model-item>
        <a-form-model-item label="待备份选项" prop="codes">
          <a-checkbox-group v-model="exportForm.codes">
            <ul class="codes-list auto">
              <li v-for="(item, index) in codesList" :key="index">
                <a-checkbox :value="item.value" :disabled="item.disabled">
                  {{item.label}}
                </a-checkbox>
              </li>
            </ul>
          </a-checkbox-group>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <a-drawer
      width="100%"
      title=""
      class="access-drawer"
      placement="right"
      :closable="false"
      :visible="visible"
      :get-container="false"
      :wrap-style="{ position: 'absolute' }"
      >
      <a-layout class="content">
        <a-layout-sider class="custom-sider">
          <a-button class="back-btn" size="small" @click="back"><a-icon type="double-left" class="font12" />返回</a-button>
          <div class="batch-menu">
            <ul>
              <li :class="menuIndex === 1 && 'cur'" @click="changeMenu(1)">待导入用户账号</li>
              <li :class="menuIndex === 2 && 'cur'" @click="changeMenu(2)">有问题用户</li>
              <li :class="menuIndex === 3 && 'cur'" @click="changeMenu(3)">有问题账号</li>
            </ul>
          </div>
        </a-layout-sider>
        <a-layout-content class="custom-content auto" style="position:relative;">
          <a-spin :spinning="loading">
            <div class="content-title auto">
              <div class="coustom-title fl" v-if="menuIndex === 1">待导入用户账号（396个）</div>
              <div class="coustom-title fl" v-if="menuIndex === 2">有问题用户（3个）</div>
              <div class="coustom-title fl" v-if="menuIndex === 3">有问题账号（123个）</div>
              <div class="coustom-title-mes fr">
                <a-icon type="info-circle" theme="filled" style="color:#1890FF;margin-right: 10px;" />您一共导入 400 个用户账号，其中：待导入用户账号 346 个，有问题用户 3 个，有问题账号 1 个
              </div>
            </div>
            <div class="content-bar auto">
              <custom-page>
                <div slot="custom">
                  <a-button size="small" class="m-r-5"> 导出当前列表 </a-button>
                  <a-button type="primary" size="small" class="m-r-5" v-show="menuIndex === 1"> 导入 </a-button>
                </div>
              </custom-page>
            </div>
            <div class="content-table auto" v-show="menuIndex === 1 || menuIndex === 2">
              <a-table :rowKey="(record,index)=>{return index}" :columns="tablecolumns" :data-source="tabledata" size="middle" :pagination="false">
                <div slot="name" slot-scope="text, record">
                  {{text}}
                  <a-popover v-if="record.faceid" class="m-r-5">
                    <template slot="content">
                      <img :src="record.faceid" alt="">
                    </template>
                    <a-icon type="picture" theme="twoTone" two-tone-color="#1890FF" />
                  </a-popover>
                  <a-tooltip v-if="record.modifyComment">
                    <template slot="title">
                      {{record.modifyComment}}
                    </template>
                    <a-icon type="info-circle" theme="twoTone" two-tone-color="#FAAD14" />
                  </a-tooltip>
                </div>
                <div slot="isDisabled" slot-scope="text">
                  <span v-if="text === '0'">
                    <a-badge status="success" :text="text" />
                  </span>
                  <span v-else>
                    <a-badge status="warning" :text="text" />
                  </span>
                </div>
              </a-table>
            </div>
            <div class="content-table auto" v-show="menuIndex === 3">
              <a-table :rowKey="(record,index)=>{return index}" :columns="tablecolumns2" :data-source="tabledata2" size="middle" :pagination="false">
                <div slot="name" slot-scope="text, record">
                  {{text}}
                  <a-popover v-if="record.faceid" class="m-r-5">
                    <template slot="content">
                      <img :src="record.faceid" alt="">
                    </template>
                    <a-icon type="picture" theme="twoTone" two-tone-color="#1890FF" />
                  </a-popover>
                  <a-tooltip v-if="record.modifyComment">
                    <template slot="title">
                      {{record.modifyComment}}
                    </template>
                    <a-icon type="info-circle" theme="twoTone" two-tone-color="#FAAD14" />
                  </a-tooltip>
                </div>
                <div slot="isDisabled" slot-scope="text">
                  <span v-if="text === '0'">
                    <a-badge status="success" :text="text" />
                  </span>
                  <span v-else>
                    <a-badge status="warning" :text="text" />
                  </span>
                </div>
              </a-table>
            </div>
            <div class="content-bar auto">
              <custom-page>
                <div slot="custom">
                  <a-button size="small" class="m-r-5"> 导出当前列表 </a-button>
                  <a-button type="primary" size="small" class="m-r-5" v-show="menuIndex === 1"> 导入 </a-button>
                </div>
              </custom-page>
            </div>
          </a-spin>
        </a-layout-content>
      </a-layout>
    </a-drawer>
  </a-layout>
</template>

<script>
import { System } from '@/model'
export default {
  data () {
    return {
      visible: false,
      menuIndex: 1,
      loading: false,
      tablecolumns: [
        {
          title: '学工号',
          dataIndex: 'schoolid'
        },
        {
          title: '姓名',
          dataIndex: 'name',
          scopedSlots: { customRender: 'name' }
        },
        {
          title: '账号',
          dataIndex: 'type1'
        },
        {
          title: '密码',
          dataIndex: 'type2'
        },
        {
          title: '手机',
          dataIndex: 'type3'
        },
        {
          title: '邮箱',
          dataIndex: 'type4'
        }
      ],
      tabledata: [],
      tablecolumns2: [
        {
          title: '错误信息',
          dataIndex: 'schoolid1'
        },
        {
          title: '学工号',
          dataIndex: 'schoolid'
        },
        {
          title: '姓名',
          dataIndex: 'name',
          scopedSlots: { customRender: 'name' }
        },
        {
          title: '账号',
          dataIndex: 'type1'
        },
        {
          title: '密码',
          dataIndex: 'type2'
        },
        {
          title: '手机',
          dataIndex: 'type3'
        },
        {
          title: '邮箱',
          dataIndex: 'type4'
        }
      ],
      tabledata2: [],
      exportVisible: false,
      options: [
        {
          value: true,
          label: '是'
        },
        {
          value: false,
          label: '否'
        }
      ],
      exporttypeOptions: [
        {
          value: 'txt',
          label: 'TXT'
        },
        {
          value: 'csv',
          label: 'CSV'
        },
        {
          value: 'xlsx',
          label: 'XLSX'
        }
      ],
      codesList: [],
      exportForm: {
        obfuscation: false,
        encryption: false,
        exporttype: 'txt',
        exportfilter: '',
        codes: []
      },
      exportfilterOptions: [
        {
          value: '',
          label: '全部'
        },
        {
          value: 'bound',
          label: '已绑定数据'
        },
        {
          value: 'registered',
          label: '已注册数据'
        }
      ]
    }
  },
  methods: {
    exportHandle () {
      this.exportVisible = true
    },
    exportClose () {
      this.exportForm = {
        obfuscation: false,
        encryption: false,
        exporttype: 'txt',
        exportfilter: '',
        codes: []
      }
      this.exportVisible = false
    },
    async exportSubmit () {
      let res = await System.backupExport(this.exportForm)
      if (!res['code']) {
        this.$router.push({
          path: '/system/backup/export',
          query: {
            id: res['data']
          }
        })
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    changeMenu (index) {
      this.menuIndex = index
    },
    back () {
      this.visible = false
    },
    showResult () {
      this.visible = true
    },
    handleChange(info) {
      this.loading = true
      // console.log('info', info)
      const status = info.file.status;
      // if (status !== 'uploading') {
      //   console.log(info.file, info.fileList);
      // }
      if (status === 'done') {
        this.loading = false
        if (!info['file'].response.code) {
          this.$router.push({
            path: '/system/backup/import',
            query: {
              id: info['file'].response['data']
            }
          })
        } else {
          // this.$message.error(info['file'].response.description);
          this.$message.error(this.errMsg[info['file'].response['code']])
        }
        // this.$message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        this.loading = false
        this.$message.error(`${info.file.name} file upload failed.`);
      }
    },
    goLog () {
      this.$router.push('/system/backup/importlog')
    },
    async getCodesList () {
      let res = await System.getCodesList()
      if (!res['code']) {
        let options = []
        res['data'].map(item => {
          if (item.status) {
            this.exportForm.codes.push(item.code)
          }
          options.push({
            label: item.name,
            value: item.code,
            disabled: item.status ? true : false
          })
        })
        this.codesList = options
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    }
  },
  created () {
    this.getCodesList()
  },
  mounted () {
  }
};
</script>

<style scoped>
.drawer-bar {
  padding: 8px 0 20px;
}

.batch-wrap {
  width: 422px;
  margin: 0 auto;
}
.batch-title {
  text-align: center;
  line-height: 62px;
}
.batch-top {
  width: 420px;
  border: 1px solid #91D5FF;
  background: #E6F7FF;
  text-align: center;
  padding: 15px 0;
}
.batch-upload {
  margin-top: 15px;
}
.excel-icon i {
  color: rgba(0,0,0,0.10)!important;
  margin-top: 15px;
}
.excel-text {
  color: rgba(0,0,0,0.25)!important;
  font-size: 14px!important;
  margin-bottom: 10px!important;
}
.excel-btn {
  padding-bottom: 20px;
}

.go-log-btn {
  text-decoration: underline;
  margin-left: 20px;
}

.batch-content2 {
  border-top: 1px solid rgba(0,0,0,0.06);
  margin-top: 30px;
  padding-top: 10px;
}

.batch-upload-box {
  border: 1px dashed rgba(0,0,0,0.15);
  border-radius: 2px;
  text-align: center;
  padding: 30px 0;
}
.content-bar {
  padding: 8px 0;
}
.batch-menu {
  width: 100%;
  padding-top: 50px;
}
.batch-menu ul li {
  line-height: 50px;
  cursor: pointer;
  font-size: 16px;
  color: rgba(0,0,0,0.65);
  text-indent: 40px;
}
.batch-menu ul li.cur {
  color: rgba(0,0,0,0.85);
  font-weight: bold;
  background: #E6F7FF;
}

.codes-list {
  padding-top: 5px;
}
.codes-list li {
  width: 33%;
  float: left;
  padding: 5px 0;
}
</style>
